@import "../../theme/variables";

.top-navbar {
  border-bottom: 1px solid #dee2e6;
  height: 4rem;
  padding-left: $layout-padding-left;
  padding-right: $layout-padding-right;
  background-color: white;

  .nav-date {
    font-size: 13px;
  }

  .nav-usericon {
    width: 32px;
    height: 32px;
    display: inline-block;
    padding: 3px;
    text-align: center;
  }
}