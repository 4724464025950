
$font-size-base: 0.875rem;

// colors
$bg-alt: #f8f9fa;

//Traffic Status Color
$free-flow : #84ca50;
$dense : #f07d02;
$congestion : #e60000;
$heavy-congestion : #9e1313;
$line-color : #84a7dd;
$toggle-icon : #19b34d;
$police-display-image : cyan;

//Dropdown Color
$gray-600: #6c757d !default;
$gray-900: #212529 !default;
$white : white ;

// Layout variables
$layout-padding-left: 200px;
$layout-padding-right: 200px;

//height
$top-navbar-height: 65px;
$content-height: calc(100vh - 65px);
