@import "./variables";

// Layout Classes
.page {
  height: 100vh;
  position: relative;
}

.content-wrapper {
  position: relative;
  padding-left: $layout-padding-left;
  padding-right: $layout-padding-right;
  background-color: $bg-alt;
  height: $content-height;
}

.content {
  position: relative;
  height: $content-height;
}


.nav-date {
  span {
    display: block;
    margin-right: 10px;
    font-size: 18px;
  }

  .date {
    font-size: 12px;
  }

}

.card-login {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  padding-top: 40px;
  padding-bottom: 60px;
  margin-top: 120px;


}


// table
.table {

  .sub-text {
    display: block;
    color: $gray-600;
  }
}

.table-compact {
  border: 1px solid $gray-400;

  th,
  td {
    padding: 12px 12px;
    vertical-align: middle;
  }

  td {
    .toggle-icon {
      font-size: 28px;
      color: $toggle-icon;
    }
  }

  // className for changing traffic color
  .traffic-status {
    display: inline-block;
    width: 30px;
    height: 30px;
  }
}

//Traffic Status Color Code
.free-flow {
  border-radius: 50%;
  background-color: $free-flow;
}
.dense {
  border-radius: 50%;
  background-color: $dense;
}
.congestion {
  border-radius: 50%;
  background-color: $congestion;
}
.heavy-congestion {
  border-radius: 50%;
  background-color: $heavy-congestion;
}

//Police display image

.police-upload-image {
  width: 700px;
  height: 600px;
}

// for changing line color
.hr-color {
  border: 1px solid $line-color;
}

//Image upload in police user
.upload-icon {
  font-size: 200px;
  cursor: pointer;
}

//Truncating text in table
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 340px;
}


// for Leaflet map
.leaflet-container {
  width: 100%;
  height: 100%;
}

.map-container {
  height: 100%;
  // width: 100%;
}

.map-cont {
  width: 100%;
  height: calc(100vh - 65px);
}

.map {
  height: 650px;
}

.marker-traffic-state {
}

.traffic-status-dot {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
