@import "./theme/variables";

@import "@fortawesome/fontawesome-free/css/all.css";

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "leaflet/dist/leaflet.css";

@import "bootstrap-daterangepicker/daterangepicker.css";

@import "./theme/style.scss";

